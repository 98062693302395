import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { addCloudinaryTransformPreset } from '../lib/util'

import styles from './project-preview.module.css'

export default ({ project, linkPrefix }) => (
  <div className={styles.preview}>
    <Link to={`/${linkPrefix}/${project.slug}`} className="hover-anim">
      <img alt={project.title} className={styles.previewImage}
        src={addCloudinaryTransformPreset(project.coverImage[0].secure_url, 'project_cover')} />
      <h3 className={styles.previewTitle}>
        {project.title}
      </h3>
    </Link>
  </div>
)
